import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from "moment";
const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
};
createApp(App)
  .use(router)
  .use(store).use(moment)
    .use(VueSweetalert2,options)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
