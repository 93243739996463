import axios from 'axios';

const API_URL = 'https://admissions.supdeco.sn/api/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
          matricule_etudiant: user.username,
          password: user.password,
          plateforme:'etudiants',
      })
      .then(response => {
        if (response.data.access_token) {
            localStorage.setItem('token', response.data.access_token)
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    return axios.post(API_URL + 'logout',{}, { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
    .then((r) => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        
        return r
    })
    .catch((e) => {
      return e
    })
   // localStorage.removeItem('');
  }

}

export default new AuthService();