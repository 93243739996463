import { createWebHistory, createRouter } from "vue-router";
import HomePage from "./components/HomePage.vue";
import LoginPage from "./components/LoginPage.vue";
import DefaultLayout from "./components/Layouts/TheDefaultLayout.vue"
import DashboardLayout from "./components/Layouts/TheDashboardLayout.vue"
//import Register from "./components/Register.vue";
// lazy-loaded
const ProfilePage = () => import("./components/ProfilePage.vue")
//const BoardAdmin = () => import("./components/BoardAdmin.vue")
//const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")

const MyToolsPage =()=>import('./views/MyToolsPage.vue')
const FormRequestSDNPage =()=>import('./views/FormRequestSDNPage.vue')
const FormRequestScolarite =()=>import('./views/FormRequestScolarite.vue')
const FormUploadDocument =()=>import('./views/FormUploadDocument.vue')
const TrombinoscopePage =()=>import('./views/TrombinoscopePage.vue')
const DocumentPage =()=>import('./views/DocumentPage.vue')
const ActualitesPage =()=>import('./views/ActualitesPage.vue')

const routes = [

  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/login",
    component: DefaultLayout,
    children:[
        {
            path:'',
            component:LoginPage,
            meta:{
                title:"EXTRANET SUPDECO",
            },
        }
    ],
  },
 
  {
    path: "/",
    name: "profile",

    // lazy-loaded
    component: DashboardLayout,
    children:[
        {
            path:'',
            component:ProfilePage,
            meta:{
                title:"Tableau de bord | EXTRANET SUPDECO",

            },
        },
        {
            path:'/planning',
            component:ProfilePage,
            meta:{
                title:"PLANNING | EXTRANET SUPDECO",
            },
        },
        {
            path:'/my-tools',
            component:MyToolsPage,
            meta:{
                title:"MES OUTILS | EXTRANET SUPDECO",
            },
        },
        {
            path:'/requests/it-services',
            component:FormRequestSDNPage,
            meta:{
                title:"SOUCIS IT | EXTRANET SUPDECO",
            },
        },
        {
            path:'/requests/scolarites',
            component:FormRequestScolarite,
            name:"help_scolarite",
            meta:{
                title:"J'ai besoin d'un document | EXTRANET SUPDECO",
            },
        },
        {
            path:'/requests/upload-document',
            component:FormUploadDocument,
            name:"upload_document",
            meta:{
                title:"Je fais parvenir un document | EXTRANET SUPDECO",
            },
        },
        {
            path:'/academic-life/trombinoscope',
            name:"trombinoscope",
            meta:{
                title:"Trombinoscope | EXTRANET SUPDECO",
            },
            component:TrombinoscopePage,

        },
        {
            path:'/academic-life/actualies',
            name:"actualies",
            meta:{
                title:"Actualites | EXTRANET SUPDECO",
            },
            component:ActualitesPage,

        },
        {
            path:'/academic-life/documents',
            name:"documents_page",
            meta:{
                title:"Notes d'informations | EXTRANET SUPDECO",
            },
            component:DocumentPage,

        },
    ]
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/home'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }


    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
  
    // trying to access a restricted page + not logged in
    // redirect to login page

  });



export default router;