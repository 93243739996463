<template>

    <router-view></router-view>
</template>

<script>

export default {
    name:'TheDefaultLayout',
    computed: {
        currentUser() {
        return this.$store.state.auth.user;
        },  
    }
}
</script>