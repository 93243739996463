<template>
  <div id="wrapper" class="theme-cyan">



    <!-- Top navbar div start -->
    <nav class="navbar navbar-fixed-top">
      <div class="container-fluid ">
        <nav class="navbar-fixed-top">
          <div class="container-fluid content_change">
            <div class="navbar-brand">
              <button type="button" @click="toggleSidebar" class="btn-toggle-offcanvas"><i class="fa fa-bars"></i></button>
              <button type="button" @click="toggleSidebar" class="btn-toggle-fullwidth"><i class="fa fa-bars"></i></button>
              <router-link to="/" >
                <img :src="logo">
              </router-link>
              <div class="inlineblock m-l-10">
                <label>Votre espace : </label>
                <select v-model="classe_id" class="form-control" @change="changeClasse">
                  <option v-for="(classe,index) in classes" :key="index"  :value="classe.id">{{classe.intitule}}</option>
                </select>
              </div>

            </div>

            <div class="navbar-right">


              <div id="navbar-menu">
                <ul class="nav navbar-nav">

                  <li>
                    <a @click="logOut" href="#" class="icon-menu"><i class="fa fa-power-off"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

      </div>
    </nav>

    <!-- main left menu -->
    <div id="left-sidebar" class="sidebar" :class="{ 'active': sidebarVisible }">
      <button type="button" class="btn-toggle-offcanvas"><i class="fa fa-arrow-left"></i></button>
      <div class="sidebar-scroll">
        <div class="user-account">
          <img :src="avatar" class="rounded-circle user-photo" alt="User Profile Picture">
          <div class="dropdown">
            <strong>
            <span>{{currentUser?.candidat.prenom}}</span>
              <span class="text-uppercase">{{" "+currentUser?.candidat.nom}} </span>
            </strong>
             <br>

            <span class="email_ecole"> {{ currentUser?.data.email_ecole }}</span> <br v-if="currentUser?.data.email_ecole">
            <span>{{ currentUser?.data.matricule_etudiant }}</span>
            <a href="javascript:void(0);" class="dropdown-toggle user-name" data-toggle="dropdown">
             <u>Mon profil</u>
            </a>
            <ul class="dropdown-menu dropdown-menu-right account">
              <li><a href="page-profile2.html"><i class="icon-user"></i>My Profile</a></li>
              <li><a href="app-inbox.html"><i class="icon-envelope-open"></i>Messages</a></li>
              <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>
              <li class="divider"></li>
              <li><a href="page-login.html"><i class="icon-power"></i>Logout</a></li>
            </ul>
          </div>

        </div>

        <!-- Tab panes -->
        <div class="tab-content padding-0">
          <div class="tab-pane active" id="menu">
            <nav id="left-sidebar-nav" class="sidebar-nav">
              <ul id="main-menu" class="metismenu li_animation_delay">
                <li :class="{ 'active': $router.currentRoute.value.path==='/' }">
                  <router-link to="/"  >
                    <i class="fa fa-dashboard"></i><span>Accueil</span>
                  </router-link>

                </li>
                <li :class="{ 'active': $router.currentRoute.value.path==='/planning' }">
                  <router-link to="/planning"  >
                    <i class="fa fa-calendar"></i><span>Planning</span>
                  </router-link>


                </li>
                <li>
                  <a  class="has-arrow" @click="toggleSubMenuEtude" >
                    <i class="fa fa-book"></i>
                    <span>Mes études</span></a>
                  <ul :style="{ display: subMenuVisibleEtude ? 'block' : 'none' }">
                    <li :class="{ 'active': $router.currentRoute.value.path.includes('documents') }">
                      <router-link :to="{name:'documents_page'}">Mes supports de cours</router-link>
                    </li>
                  </ul>
                </li>
                <li :class="{ 'active': $router.currentRoute.value.path.includes('/academic-life') }">
                  <a  class="has-arrow" @click="toggleSubMenuCampus" >
                    <i class="fa fa-university"></i><span>Mon campus</span>
                  </a>
                  <ul :style="{ display: subMenuVisibleCampus ? 'block' : 'none' }">
                    <li :class="{ 'active': $router.currentRoute.value.path.includes('documents') }">
                      <router-link :to="{name:'documents_page'}">Notes d'informations</router-link>
                    </li>
                    <li :class="{ 'active': $router.currentRoute.value.path.includes('trombinoscope') }">
                      <router-link :to="{name:'trombinoscope'}">Trombinoscope</router-link>

                    </li>
                    <li :class="{ 'active': $router.currentRoute.value.path.includes('actualies') }">
                      <router-link :to="{name:'actualies'}">Actualités</router-link>

                    </li>

                  </ul>
                </li>
                <li :class="{ 'active': $router.currentRoute.value.path==='/my-tools' }">
                  <router-link to="/my-tools"  >
                    <i class="fa fa-diamond"></i><span>Mes outils</span>
                  </router-link>

                </li>
                <li :class="{ 'active': $router.currentRoute.value.path.includes('/requests') }">
                  <a  class="has-arrow" @click="toggleSubMenu">
                    <i class="fa fa-sticky-note"></i><span>Mes demandes</span>
                  </a>
                  <ul :style="{ display: subMenuVisible ? 'block' : 'none' }">
                    <li :class="{ 'active': $router.currentRoute.value.path.includes('scolarite') }">
                      <router-link :to="{name:'help_scolarite'}">J'ai besoin d'un document</router-link>
                    </li>
                    <li :class="{ 'active': $router.currentRoute.value.path.includes('upload-document') }">
                      <router-link :to="{name:'upload_document'}">Je fais parvenir un document</router-link>
                    </li>
                    <li :class="{ 'active': $router.currentRoute.value.path.includes('it-services') }">
                      <router-link to="/requests/it-services"  >
                        J'ai un soucis avec mes outils numériques
                      </router-link>
                    </li>
                  </ul>

                </li>
                <li>
                  <a href="https://outlook.office.com/mail/" target="_blank"> <i class="fa fa-envelope"></i><span>Messagerie</span></a>

                </li>

              </ul>
            </nav>
          </div>

        </div>
      </div>
    </div>


    <!-- mani page content body part -->
    <div id="main-content" :class="containerClasses">
      <div >
        <router-view></router-view>
      </div>
    </div>

  </div>

</template>

<script>
import '@/assets/css/main.css'
import '@/assets/vendor/bootstrap/css/bootstrap.min.css'
import '@/assets/vendor/font-awesome/css/font-awesome.min.css'
import logo from '@/assets/images/logo.webp'
import avatar from '@/assets/images/icon-visitor.png'
import ClassesService from "@/services/Campus/classes-service";
import { mapMutations } from 'vuex';
import {emitSelectChanged } from "@/eventBus";

export default {
    name:'TheDashboardLayout',
  data() {
    return {
      sidebarVisible: window.innerWidth >= 768, // Initialise sidebarVisible en fonction de la largeur initiale de l'écran

      logo:logo,
      avatar:avatar,
      subMenuVisible: false,
      subMenuVisibleCampus: false,
      subMenuVisibleEtude: false,
      isMobile: false,
      classes:[],
      classe_id:'',
    };
  },
    computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    },

      containerClasses() {
        return {
          'container-fluid': !this.sidebarVisible,
          'full-width': this.sidebarVisible,
        };
      },


  },



  mounted() {
    window.addEventListener('resize', this.handleResize);

    if (this.isMobile) {
      this.sidebarVisible = false;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },

  created() {

    this.all_classes();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    this.checkIfMobile();

    window.addEventListener('resize', this.checkIfMobile);


  },

  unmounted() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
      ...mapMutations(['ajouterValeur']),
    changeClasse(){
        this.ajouterValeur(this.classe_id)
      // Après avoir modifié la valeur, vous pouvez émettre un événement pour notifier le composant B
      //this.$emit('valeur-modifiee', this.classe_id);

      emitSelectChanged(this.classe_id)
    },


    async all_classes(){
      let userID= this.$store.state.auth.user.data.id;
      this.classes=[];
      try {
        const data = await ClassesService.getListeClassesByStudent(userID);

        data.forEach((res)=>{
          let p={
            id:res.classe.id,
            intitule:res.classe.intitule,
          }
          if(res.is_actual===1){
            this.classe_id=res.classe.id;
          }
          this.classes.push(p);
        })
      }catch (error) {
        console.log(error.message);
        // Gérer l'erreur en conséquence
      }
    },

    toggleSubMenu() {
      this.subMenuVisible = !this.subMenuVisible;
      if(this.subMenuVisibleCampus){
        this.subMenuVisibleCampus = !this.subMenuVisibleCampus;
      }
      if(this.subMenuVisibleEtude){
        this.subMenuVisibleEtude = !this.subMenuVisibleEtude;
      }

    },

    toggleSubMenuEtude() {
      this.subMenuVisibleEtude = !this.subMenuVisibleEtude;
      if(this.subMenuVisibleCampus){
        this.subMenuVisibleCampus = !this.subMenuVisibleCampus;
      }
      if(this.subMenuVisible){
        this.subMenuVisible = !this.subMenuVisible;
      }
    },

    toggleSubMenuCampus() {
      this.subMenuVisibleCampus = !this.subMenuVisibleCampus;
      if(this.subMenuVisible){
        this.subMenuVisible = !this.subMenuVisible;
      }
      if(this.subMenuVisibleEtude){
        this.subMenuVisibleEtude = !this.subMenuVisibleEtude;
      }
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
      // Vérifier la largeur de l'écran lors de la création du composant

    },



    handleResize() {
      // Mettre à jour sidebarVisible lorsque la taille de l'écran change
      this.sidebarVisible = window.innerWidth >= 768;

      console.log("hResize",this.sidebarVisible)
      this.toggleSidebar()
    },

    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;

      console.log("resr",this.sidebarVisible)

      // Ajoute ou supprime la classe 'body-sidebar-active' du body
      document.body.classList.toggle('layout-fullwidth', this.sidebarVisible);
    },
    triggerEvent() {
      this.sidebarVisible = true;
      console.log('triggerEvent',this.sidebarVisible)
    },

    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768; // Modifier selon votre seuil de mobile
      console.log("checkIfMobile",this.isMobile)
    },

  },



  beforeRouteUpdate(to, from, next) {
    if (this.isMobile) {
      this.sidebarVisible = true;
      console.log("beforeRouteUpdate",this.sidebarVisible)
      document.body.classList.toggle('layout-fullwidth', this.sidebarVisible);
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isMobile) {
      this.sidebarVisible = true;

      console.log("beforeRouteLeave",this.sidebarVisible)
    }
    next();
  },




}
</script>

<style>
.sidebar{
  background: #313f96 !important;
  box-shadow: inset -2px -2px 8px 0 #7f9bc5 !important;
  color: white !important;
  margin-top: -20px !important;
}

.sidebar.active {
  display: block;
}

.full-width {
  width: 100% !important;
}

.email_ecole {
  display: inline-block;
  width: 200px;
  word-wrap: break-word;
}



/* Media query pour les écrans mobiles */
@media only screen and (max-width: 600px) {
  .navbar-fixed-top .navbar-brand img {
    width: 60px; /* Largeur de l'image réduite à 45px pour les mobiles */
    height : 80px;
  }

  .content_change{
    padding:0px !important;
  }
}
</style>