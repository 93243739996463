import ApiService from "@/services/api.service";

export default class ClassesService{
    static async getListeEtudiantInClasses(classeID,param_url={}) {
        try {
            return await ApiService.fetchDataNew("etudiants/classes/listes-etudiants-in-classes/"+classeID,{ headers:{Authorization: 'Bearer ' + localStorage.getItem('token')},params:param_url});

        } catch (error) {
            throw new Error(error);
        }
    }
    static async getListeClassesByStudent(etudiantID,param_url={}) {
        try {
            return await ApiService.fetchDataNew("etudiants/classes/listes-classe-by-student/"+etudiantID,{ headers:{Authorization: 'Bearer ' + localStorage.getItem('token')},params:param_url});

        } catch (error) {
            throw new Error(error);
        }
    }

}