// eventBus.js
import mitt from 'mitt';

const emitter = mitt();
let selectChangedEmitted = false;

export function emitSelectChanged(value) {
    selectChangedEmitted = true;
    emitter.emit('boutonChange', value);
    console.log("test event bus", value)
}

export { emitter, selectChangedEmitted };