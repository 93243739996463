import axios from 'axios';

import form from 'vform'

export default class ApiService{

    static API_URL = "https://admissions.supdeco.sn/api/";

    constructor() {
        this.formData = form;
    }


    static async fetchDataNew(current_url,params_url=null) {
        try {
            const response = await axios.get(ApiService.API_URL+current_url, params_url);
            return response.data;
        } catch (error) {
            throw this.handleApiError(error);
        }
    }

    static async formPostData(form,url,config) {
        try {
            this.formData = form;
            const response = await this.formData.post(ApiService.API_URL+url,config);
            return { data: response.data, error: null };
        } catch (error) {
            console.log("erroooooooooooooor",error.response.status)
            return { data: null, error: this.handleApiError(error)  };
        }
    }

    static handleApiError(error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = 'Requête incorrecte';
                    break;
                case 404:
                    error.message = 'Ressource non trouvée';
                    break;
                case 500:
                    error.message = error.response.data ? error.response.data : 'Erreur interne du serveur';
                    break;
                default:
                    error.message = 'Une erreur est survenue';
            }
        } else {
            error.message = 'Une erreur est survenue';
        }

        return error;
    }

}