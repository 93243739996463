import { createStore } from "vuex";
import { auth } from "./auth.module";
import createPersistedState from 'vuex-persistedstate'
const store = createStore({
  state: {
    maClasse: null
  },
  modules: {
    auth,
  },
  mutations: {
    ajouterValeur(state, valeur) {
      state.maClasse = valeur;
    }
  },
  getters: {
    getMaClasse: state => state.maClasse
  },
  plugins: [createPersistedState()]
});

export default store;