<template>
  <!-- WRAPPER -->
  <div id="wrapper" class="theme-cyan">

    <div class="vertical-align-wrap">
      <div class="vertical-align-middle auth-main">
        <div class="auth-box">
          <div class="top">
            <img :src="logo" alt="Iconic">
          </div>
          <div class="card">
            <div class="header">
              <h3 class="lead">Bienvenue sur votre Extranet</h3>
            </div>
            <div class="body">
              <Form @submit="handleLogin" :validation-schema="schema">
                <div class="form-group">
                  <label for="username">Identifiant</label>
                  <Field name="username" type="text" class="form-control" />
                  <ErrorMessage name="username" class="error-feedback" />
                </div>
                <div class="form-group">
                  <label for="password">Mot de passe</label>
                  <Field name="password" type="password" class="form-control" />
                  <ErrorMessage name="password" class="error-feedback" />
                </div>

                <div class="form-group">
                  <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
              ></span>
                    <span>Login</span>
                  </button>
                </div>

                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
  </template>
  
  <script>
  import '@/assets/css/main.css'
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";
  import logo from '@/assets/images/logo-supdeco-blanc.png'
  import { mapMutations } from 'vuex';
  export default {
    name: "LoginPage",
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        username: yup.string().required("Username is required!"),
        password: yup.string().required("Password is required!"),
      });
  
      return {
        loading: false,
        message: "",
        schema,
        logo:logo
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
    },
    created() {
      if (this.loggedIn) {
        this.$router.push("/");
      }
    },
    methods: {
      ...mapMutations(['ajouterValeur']),
      handleLogin(user) {
        this.loading = true;
  
        this.$store.dispatch("auth/login", user).then(
          (data) => {

            this.ajouterValeur(data.inscription.classe_id);
            this.$router.push("/");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      },
    },
  };
  </script>
  
  <style scoped>
 .profile-img-card {
  width: 100px;
  height: 100px;
  margin: auto;
 }
 .top{
   margin: auto;
   display: flex;
   justify-content: center;
   align-items: center;

 }
  </style>